export const getScrollElement = (el: any) => {
  let newEl = el;
  let elTop = newEl.offsetTop - newEl.offsetHeight * 3;
  while (newEl.offsetParent) {
    newEl = newEl.offsetParent;
    elTop += newEl.offsetTop;
  }
  return elTop;
};

export const isShouldFetch = () => {
  const footerEl = document.querySelector('.footer-wrapper');
  if (footerEl) {
    const scrollElement = getScrollElement(footerEl);
    return document.documentElement.scrollTop + 500 > scrollElement;
  }
  return false;
};
