import _ from 'lodash';
import { GetServerSideProps, NextPage } from 'next';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { pageNextSEO } from 'src/utils/next-seo';

import * as Sentry from '@sentry/react';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { TInitPropsIndexPage, getIndexPage, initPropsPageIndex } from 'src/services/article.service';
import { decryptionClient } from 'src/services/member-auth/utils/decryption-payload';
import { dropSomeField } from 'src/services/member-auth/utils/helper';
import { KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import { isShouldFetch } from 'src/utils/detect-scrolling';
import useUser from 'src/utils/useUser';

const DynamicAds = dynamic(import('src/utils/ads/dynamic-ads'), { ssr: true });
const SectionCover = dynamic(import('src/section-events/events-009/section-cover'), { ssr: true });
const SectionLandingPage = dynamic(import('src/section-events/events-008/section-landing-page'), { ssr: true });
const SectionOne = dynamic(import('src/components/sections/section-home/section-one'), { ssr: true });
const SectionTwo = dynamic(import('src/components/sections/section-home/section-two'), { ssr: true });
const SectionThree = dynamic(import('src/components/sections/section-home/section-three'));
const SectionFour = dynamic(import('src/components/sections/section-home/section-four'));
const SectionMember = dynamic(import('src/services/member-auth/components/section-member'));
const Layout = dynamic(import('src/components/layout'));

type Props = TInitPropsIndexPage;
const IndexPage: NextPage<Props> = props => {
  const data = useUser();

  const [sessionSevId, setSessionSevId] = useState<any>(null);
  const [sectionMember, setSectionMember] = useState<any>({});
  useEffect(() => {
    try {
      const checkUser = async () => {
        if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
          const response = await fetch(`/api/auth/metadata`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response.ok) {
            const res = await response.json();
            localStorage.setItem('login-meta-data', res);
            setSectionMember(decryptionClient(res));
          }
        }
      };

      const loginMetaData = localStorage.getItem('login-meta-data');
      const decryptLoginMetaData = !_.isEmpty(loginMetaData) ? decryptionClient(loginMetaData) : {};
      const expireCondition: boolean = !_.isEmpty(loginMetaData) ? new Date(decryptLoginMetaData?.expire) < new Date() : true;

      if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
        setSessionSevId(decryptionClient(data?.user));
        _.isEmpty(loginMetaData) || expireCondition ? checkUser() : setSectionMember(decryptLoginMetaData);
      }
    } catch (err: any) {
      console.log(err);
    }
  }, [data]);
  const { ads, sectionOne, sectionCover, sectionLandingPage } = props;

  const router: NextRouter = useRouter();

  const sectionsNeedShow: string[] = ['SectionThree', 'SectionFour'];

  const nextSectionIndexRef: any = useRef(0);
  const sectionsVisibleRef: any = useRef([]);
  const [sectionsVisible, setSectionsVisible]: any = useState([]);

  const loadMoreArticles = () => {
    if (sectionsNeedShow.length > nextSectionIndexRef.current) {
      if (isShouldFetch()) {
        const currentIndex = nextSectionIndexRef.current;
        const section = sectionsNeedShow[currentIndex];

        nextSectionIndexRef.current = currentIndex + 1;
        // eslint-disable-next-line no-unsafe-optional-chaining
        sectionsVisibleRef.current = [...sectionsVisibleRef?.current, section];
        setSectionsVisible(sectionsVisibleRef.current);

        setTimeout(() => {
          loadMoreArticles();
        }, 1000);
      } else {
        document.addEventListener('scroll', handleScroll);
      }
    }
  };

  const handleScroll = async () => {
    if (isShouldFetch()) {
      document.removeEventListener('scroll', handleScroll);
      loadMoreArticles();
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo {...pageNextSEO({ data: props })} />
      <SectionLandingPage data={sectionLandingPage} />
      <SectionCover data={sectionCover} />
      <Layout sessionSevId={sessionSevId}>
        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />}
        {!_.isEmpty(sectionOne.highlight1) && (
          <SectionOne
            highlight1={sectionOne.highlight1}
            // ! DATALAYER
            eventDataLayer={{
              type: DATALAYER_TYPE.TRACK_POSITION,
              router: router?.pathname,
              section: 'section-1',
              position: 'ใต้:header'
            }}
          />
        )}


        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_1} data={ads} />}

        {!_.isEmpty(sessionSevId) && !_.isEmpty(sectionMember) && (
          <SectionMember
            data={{
              user: dropSomeField(sectionMember?.user, ['menu', 'tags', 'articlesTags', 'articlesCategories']),
              menu: sectionMember?.menu,
              tags: sectionMember?.tags,
              articlesTags: sectionMember?.articlesTags,
              articlesCategories: sectionMember?.articlesCategories
            }}
          />
        )}

        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_2} data={ads} />}

        <SectionTwo
          datalockII={sectionOne?.highlight2?.data}
          headerLatestNews={sectionOne.headerLatestNews}
          block1={sectionOne.block1}
          block2={sectionOne.block2}
          block3={sectionOne.block3}
          // ! DATALAYER
          eventDataLayer={{
            type: DATALAYER_TYPE.TRACK_POSITION,
            router: router?.pathname,
            section: 'section-2',
            position: 'ใต้:section-1'
          }}
        />

        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.INARTICLE_3} data={ads} />}

        {sectionsVisible.includes('SectionThree') && (
          <SectionThree
            datalockII={sectionOne?.highlight2?.data}
            dataTags={sectionOne?.tags}
            // ! DATALAYER
            eventDataLayer={{
              type: DATALAYER_TYPE.TRACK_POSITION,
              router: router?.pathname
            }}
          />
        )}

        {sectionsVisible.includes('SectionFour') && (
          <SectionFour
            // ! DATALAYER
            eventDataLayer={{
              type: DATALAYER_TYPE.TRACK_POSITION,
              router: router?.pathname
            }}
          />
        )}

        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.POSTARTICLE} data={ads} />}
        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.AD1X1} data={ads} />}
        {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.LIGHTBOX} data={ads} />}
      </Layout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ resolvedUrl, res }) => {
  let initProps: Props = {
    ..._.cloneDeep(initPropsPageIndex)
  };
  res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

  try {
    const initPropsIndexPage = await getIndexPage({ resolvedUrl });
    initProps = {
      ...initProps,
      ...initPropsIndexPage
    };
  } catch (err: any) {
    // import * as Sentry from '@sentry/browser';
    Sentry.captureException(new Error(`URL: ${resolvedUrl}` + `➡️ ${err.message}`));
    initProps.errMessage = err.message;
    initProps.statusCode = err?.response?.status || 500;
  }
  return {
    props: initProps
  };
};
export default IndexPage;
